import { connect } from "react-redux";
import { PRODUCT_EXPERIENCES_WITH_HOTEL } from "app/constants";

import TravelDates from "app/pages/.shared/TravelDates/TravelDates";
import withRouter from "app/utils/hocs/withRouter";

const mapStateToProps = state => {
	let booking = state.booking;
	const isWithHotel = PRODUCT_EXPERIENCES_WITH_HOTEL.includes(
		state?.ficheProduit?.productExperience
	);

	return {
		booking,
		isWithHotel,
	};
};

export default withRouter(connect(mapStateToProps)(TravelDates));
