import PropTypes from "prop-types";

const Loader = ({ width, height }) => {
	return (
		<img
			className="loader-spinner"
			style={{ height, width }}
			src="/static/icons/loader.gif"
			alt="loader"
		/>
	);
};

Loader.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Loader.defaultProps = {
	width: 100,
	height: 100,
};

export default Loader;
